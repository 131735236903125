<template>
  <div class="reward-postpone-check-list">
    <div style="padding-bottom: 20px">
      <MemberSearch :model.sync="search.member" @change="onSearch" />
    </div>
    <el-table :data="displayData">
      <template #empty><EmptyBlock /></template>
      <el-table-column label="會員電話" prop="phone" align="center" />
      <el-table-column label="會員姓名" prop="name" align="center" />
      <el-table-column label="獎勵類型" prop="rewardType" align="center" />
      <el-table-column label="前年度有效獎勵" prop="balance" align="center" />
      <el-table-column label="前年度獎勵到期日" prop="walletExpDate" align="center" />
      <el-table-column label="可展延" prop="walletExpDate" align="center">
        <template #header>
          <div class="flex items-center justify-center">
            <p>可展延</p>
            <TipInfo width="200">
              <div>
                不可展延的可能原因：<br>
                (1) 點數已過期或無有效點數<br>
                (2) 該會員已經執行過展延<br>
              </div>
            </TipInfo>
          </div>
        </template>
        <template slot-scope="scope">
          <Tag :type="scope.row.postponed.tagType">{{ scope.row.postponed.label }}</Tag>
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      :curPage="tableData.page"
      :pageLimit="tableData.perPage"
      :total="tableData.totalDataCount"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, watch, ref } from 'vue'
import { get, map, filter, find, isEmpty } from 'lodash'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { rewardPostponeTaskTypesConfig } from '@/config/rewardPostpone'
import { GetMembersBatchPointYearlyWallet, GetMembersBatchCashbackYearlyWallet } from '@/api/memberBatchTask'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import dayjs from '@/lib/dayjs'
import { formatDate } from '@/utils/date'
import TipInfo from '@/components/TipInfo.vue'

export default defineComponent({
  name: 'RewardPostponeCheckList',
  components: { EmptyBlock, MemberSearch, TipInfo },
  props: {
    rewardType: String,
    tableData: { type: Object, default: () => ({ page: 1, perPage: 10, data: [], totalDataCount: 0 }) },
  },
  setup (props, { emit }) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const search = reactive({
      member: null,
    })
    const membersYearlyWallet = ref([])

    const displayData = computed(() => {
      return map(filter(props.tableData.data, m => !m.isRemove), (member) => {
        const wallet = find(membersYearlyWallet.value, { memberId: get(member, 'id') })
        const yearlyWallet = find(get(wallet, 'pointWalletYearExpirations'), { year: dayjs().year() - 1 })

        let canPostpone
        if (!get(yearlyWallet, 'expiredAt') && get(yearlyWallet, 'balance')) {
          canPostpone = true
        } else if (!get(yearlyWallet, 'expiredAt') || !get(yearlyWallet, 'balance')) {
          canPostpone = false
        }

        return {
          id: get(member, 'id'),
          phone: get(member, 'UserInfo.phone'),
          name: get(member, 'UserInfo.name'),
          rewardType: get(rewardPostponeTaskTypesConfig, `${props.rewardType}.label`),
          balance: yearlyWallet ? get(yearlyWallet, 'balance') : '-',
          walletExpDate: yearlyWallet ? formatDate(get(yearlyWallet, 'expirationDate')) : '-',
          postponed: canPostpone ? { label: '是', tagType: 'action' } : { label: '否', tagType: 'info' },
        }
      })
    })

    const onPageChange = (newPage) => {
      emit('pageChange', newPage)
    }

    const onSearch = () => {
      emit('search', { ...search })
    }

    const refreshWalletData = async (data) => {
      let apiMethod
      if (props.rewardType === rewardPostponeTaskTypesConfig.pointYearlyExtension.value) {
        apiMethod = GetMembersBatchPointYearlyWallet
      } else if (props.rewardType === rewardPostponeTaskTypesConfig.cashbackYearlyExtension.value) {
        apiMethod = GetMembersBatchCashbackYearlyWallet
      }

      simpleFetch(apiMethod, {
        shopId: shopId.value,
        year: dayjs().year() - 1,
        memberIds: map(data, 'id'),
      }, (res) => {
        membersYearlyWallet.value = res
      })
    }

    watch(() => props.tableData.data, async (newData) => {
      if (!newData || isEmpty(newData)) return
      refreshWalletData(newData)
    }, { deep: true })

    watch(() => props.rewardType, async () => {
      const data = get(props.tableData, 'data')
      if (!data || isEmpty(data)) return
      refreshWalletData(data)
    })

    return { search, displayData, onPageChange, onSearch, membersYearlyWallet, get }
  },
})
</script>

<style lang="postcss" scoped>

</style>
