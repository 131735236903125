<template>
  <div class="reward-postpone-edit">
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <div class="form-block-wrapper">
        <RewardPostponeInfoBlock :form.sync="formData.info" />
        <RewardPostponeConditionBlock :form.sync="formData.condition" :rewardType="formData.info.type" />
        <RewardPostponeTriggerBlock :form.sync="formData.trigger" />
      </div>
    </el-form>

    <WarningDialog
      v-if="modal.warning"
      @confirm="onSubmit"
      @close="modal.warning = false"
    >
      <template slot="body">
        <div>
          <p>點選確定後，將會立即執行獎勵展延，確認是否仍要執行?</p>
        </div>
      </template>
    </WarningDialog>

    <PageFixedFooter
      @cancel="onCancel"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, computed, ref } from 'vue'
import RewardPostponeInfoBlock from './components/RewardPostponeInfoBlock.vue'
import RewardPostponeConditionBlock from './components/RewardPostponeConditionBlock.vue'
import RewardPostponeTriggerBlock from './components/RewardPostponeTriggerBlock.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { useRouter } from 'vue-router/composables'
import { get, map, isEmpty } from 'lodash'
import { rewardPostponeTaskTypesConfig, rewardPostponeConditionTypesConfig } from '@/config/rewardPostpone'
import { CreateMemberBatchTask } from '@/api/memberBatchTask'
import dayjs from '@/lib/dayjs'
import formUtils from '@/utils/form'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { noEmptyRules } from '@/validation'
import { onFormRulesChangeClearValidate } from '@/use/useForm'

export default defineComponent({
  name: 'RewardPostponeEdit',
  components: {
    RewardPostponeInfoBlock,
    RewardPostponeConditionBlock,
    RewardPostponeTriggerBlock,
    WarningDialog,
  },
  setup (props) {
    const router = useRouter()
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()

    const formRef = ref(null)
    const formData = reactive({
      info: {
        type: null,
        expAt: null,
      },
      condition: {
        type: null,
        tagSettings: [],
        specificMemberIds: [],
      },
      trigger: {
        planExecutionAt: null,
      },
    })
    const formRules = computed(() => {
      const rules = {
        'info.type': [noEmptyRules()],
        'info.expAt': [noEmptyRules()],
        'condition.type': [noEmptyRules()],
      }
      if (get(formData, 'condition.type') === rewardPostponeConditionTypesConfig.specific.value) {
        rules['condition.specificMemberIds'] = [noEmptyRules()]
      }
      return rules
    })
    const modal = reactive({
      warning: false,
    })

    const submitData = computed(() => {
      const rewardType = get(formData, 'info.type')
      let newExpAt = get(formData, 'info.expAt')
      if (newExpAt) newExpAt = dayjs(newExpAt).endOf('day').toDate()

      const tagSettings = map(get(formData, 'condition.tagSettings'), (setting) => ({
        includeTagIds: setting.includes,
        excludeTagIds: setting.excludes,
      }))
      const specificMemberIds = get(formData, 'condition.specificMemberIds')

      const settings = {}
      if (rewardType === rewardPostponeTaskTypesConfig.pointYearlyExtension.value) {
        settings[rewardPostponeTaskTypesConfig.pointYearlyExtension.value] = {
          year: dayjs().year() - 1,
          newExpirationDate: newExpAt,
        }
      } else if (rewardType === rewardPostponeTaskTypesConfig.cashbackYearlyExtension.value) {
        settings[rewardPostponeTaskTypesConfig.cashbackYearlyExtension.value] = {
          year: dayjs().year() - 1,
          newExpirationDate: newExpAt,
        }
      }

      return {
        shopId: shopId.value,
        type: rewardType,
        settings,
        condition: {
          type: get(formData, 'condition.type'),
          tagSettings: isEmpty(tagSettings) ? undefined : tagSettings,
          specificMemberIds: isEmpty(specificMemberIds) ? undefined : specificMemberIds,
        },
        planExecutionAt: get(formData, 'trigger.planExecutionAt'),
        // note: 'string',
      }
    })

    const onCancel = () => router.push({ name: 'RewardPostponeList' })
    const onConfirm = async () => {
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) {
        window.$message.warning(notifyMessage.formDataError)
        return
      }
      modal.warning = true
    }

    const onSubmit = async () => {
      await simpleFetch(CreateMemberBatchTask, submitData.value, (res) => {
        window.$message.success(notifyMessage.createSuccess)
        router.push({ name: 'RewardPostponeList' })
      })
    }

    onFormRulesChangeClearValidate(formRef, formRules, 50)

    return { formRef, formData, formRules, onCancel, onConfirm, onSubmit, submitData, modal }
  },
})
</script>

<style lang="postcss" scoped>
.form-block-wrapper {
    @apply flex flex-col gap-[30px];
}
</style>
